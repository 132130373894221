/* Colors */
@font-face {
  font-family: Campton;
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-light-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-light-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: bold;
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-bold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: 600;
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff") format("woff");
}
.altai-theme-content #bodyView .rvbcards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.altai-theme-content #bodyView .altai-card.rvbcard {
  min-height: 250px;
  height: 250px;
  width: 250px;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  background-color: transparent;
  box-shadow: none;
}
.altai-theme-content #bodyView .altai-card.rvbcard .altai-card-body {
  display: flex;
  flex-direction: column;
  min-height: initial;
}
.altai-theme-content #bodyView .altai-card.rvbcard .altai-card-image-wrapper {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.altai-theme-content #bodyView .altai-card.rvbcard .altai-card-image-wrapper .altai-card-image {
  height: auto;
  width: auto;
  max-width: 120px;
  max-height: 80px;
  margin-bottom: 0;
}
.altai-theme-content #bodyView .altai-card.rvbcard .altai-card-title {
  text-align: center;
}
.altai-theme-content #bodyView .altai-card.rvbcard .altai-card-title h5 {
  margin-bottom: 0.625em;
}
.altai-theme-content #bodyView .altai-card.rvbcard .altai-card-function-title {
  font-style: italic;
  font-size: 0.8em;
  text-align: center;
  margin-bottom: 5px;
}
